<template>
  <v-container fluid class="pl-10 pr-10 pb-10">
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1 class="text-red">Raise Credit Note against Invoice #{{ invoice.jobNumber }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-text-field v-model="invoice.customer_name"></v-text-field>
        <span v-if="invoice.addressOne">
          <v-text-field v-model="invoice.addressOne" label="Address line one"></v-text-field>
        </span>
        <span v-if="invoice.addressTwo">
          <v-text-field v-model="invoice.addressTwo" label="Address line two"></v-text-field>
        </span>
        <span v-if="invoice.addressThree">
          <v-text-field v-model="invoice.addressThree" label="Address line three"></v-text-field>
        </span>
        <span v-if="invoice.addressFour">
          <v-text-field v-model="invoice.addressFour" label="Address line four"></v-text-field>
        </span>
        <span v-if="invoice.addressFive">
          <v-text-field v-model="invoice.addressFive" label="Address line five"></v-text-field>
        </span>
        <span v-if="invoice.postcode">
          <v-text-field v-model="invoice.Postcode" label="Postcode"></v-text-field>
        </span>
      </v-col>
      <v-col class="col-8">
        <p>Invoice details:</p>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Description</th>
                <th class="text-left">Units</th>
                <th class="text-left">Quantity</th>
                <th class="text-left">Rate</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in invoice.lines" :key="index">
                <td>
                  {{ line.description }}
                </td>
                <td>
                  {{ line.units }}
                </td>
                <td>
                  {{ line.quantity }}
                </td>
                <td>
                  {{ line.rate | priceInPounds | currency }}
                </td>
                <td>
                  {{ (line.quantity * line.rate) | priceInPounds | currency }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-simple-table>
          <tbody>
            <tr>
              <th>Sub-total</th>
              <td>{{ invoice.value | priceInPounds | currency }}</td>
              <th>VAT</th>
              <td>{{ invoice.vat | priceInPounds | currency }}</td>
              <th>Total</th>
              <td>{{ (invoice.value + invoice.vat) | priceInPounds | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
        <p>Document number: {{ invoice.quotationId }}-66-{{ invoice.quotedBy }}-{{ invoice.invoicedBy }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="taxDate"
          persistent
          width="390px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="taxDate"
              label="Date / Tax point"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="taxDate"
            @input="modal = false"
          >
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <p>
          <v-text-field
            v-model="creditNoteReference"
            label="Credit note reference"></v-text-field>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>Add lines to credit note:</p>
      </v-col>
    </v-row>
    <v-row v-for="n in lines" :key="n">
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          v-model="newLines[n - 1].description"
          label="Description"></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-select
          :items="nominals"
          label="Nominal"
          v-model="newLines[n - 1].nominal"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-text-field
          v-model="newLines[n - 1].quantity"
          label="Quantity"
          type="number"
          min="0"
          step="1"></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-text-field
          v-model="newLines[n - 1].value"
          label="Value"
          type="number"
          min="0"
          step="0.01"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="addNewLine" small>Add line</v-btn>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-6">
        <label>Descriptive notes:</label>
        <vue-editor
          id="descriptionNotes"
          :editor-toolbar="customToolbar"
          v-model="invoice.description_notes" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <p>
          <v-text-field v-model="invoice.siteLocation" label="Delivery location"></v-text-field>
        </p>
        <p>
          <v-text-field v-model="invoice.workDescription" label="Description"></v-text-field>
        </p>
        <p>
          <v-select
            v-model="invoice.contact_id"
            label="Contact"
            :items="contacts"
            item-text="name"
            item-value="id"></v-select>
        </p>
      </v-col>
      <v-col class="col-6">
        <v-simple-table>
          <tbody>
            <tr>
              <th>Sub-total</th>
              <td>{{ newSubTotal | currency }}</td>
              <th>VAT</th>
              <td>
                <span v-if="invoice.vatStatus === 1">
                  &pound;0.00
                </span>
                <span v-else>
                  {{ newVat | currency }}
                </span>
              </td>
              <th>Total</th>
              <td>{{ newTotal | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="saveCreditNote" small>Save credit note</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          The Credit Note has been raised.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <router-link :to="`/invoices/view-credit-note/${creditNoteId}`">
            <v-btn
              color="orange darken-2"
              text
            >
              View
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from '../../axios';

export default {
  name: 'InvoicesRaiseCreditNote',
  components: {
    VueEditor,
  },
  data() {
    return {
      lines: 1,
      newLines: [{ description: '', quantity: 0, value: 0 }],
      invoice: {},
      modal: false,
      creditNoteReference: '',
      creditNoteId: 0,
      reasonForCredit: '',
      taxDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      newSubTotal: 0,
      newVat: 0,
      newTotal: 0,
      customToolbar: [
        ["bold", "italic", "underline"],
      ],
      nominals: [
        { value:4000, text:'Sale of goods' },
        { value:4001, text:'Thermo' },
        { value:4003, text:'Paint' },
        { value:4008, text:'Anti-skid' },
        { value:4015, text:'Hydroblasting' },
        { value:4020, text:'Hydroblasting (Sub-contracte)' },
        { value:4025, text:'Shotblasting' },
        { value:4030, text:'Traffic Management' },
      ],
      showMessage: false,
      contacts: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    newLines: {
      handler() {
        let subTotal = 0;
        for (let i = 0; this.newLines.length > i; i++) {
          const quantity = this.newLines[i].quantity;
          const amount = this.newLines[i].value;
          subTotal += (quantity * amount);
        }
        this.newSubTotal = subTotal;
        if (this.invoice.vatStatus > 1) {
          this.newTotal = (this.newSubTotal * 1.2);
          this.newVat = (this.newTotal - this.newSubTotal);
        } else {
          this.newTotal = this.newSubTotal;
          this.newVat = 0;
        }
      },
      deep: true,
    },
  },
  methods: {
    addNewLine() {
      this.lines += 1;
      const newLine = { description: '', quantity: 0, value: 0 };
      this.newLines.push(newLine);
    },
    saveCreditNote() {
      const postData = {};
      postData.newLines = this.newLines;
      postData.taxDate = this.taxDate;
      postData.invoice = this.invoice;
      postData.creditNoteReference = this.creditNoteReference;
      postData.reasonForCredit = this.reasonForCredit;
      postData.userId = this.$store.state.userid;
      axios.post(`/creditNotes/create/${this.$route.params.id}.json?token=${this.token}`, postData)
      .then((response) => {
        this.showMessage = true;
        this.creditNoteId = response.data.creditNote.id;
      });
    },
    getInvoice() {
      axios.get(`/invoices/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.invoice = response.data;
        this.getContacts();
      });
    },
    getContacts() {
      axios.get(`/contacts/getByCustomerId/${this.invoice.customerId}.json?token=${this.token}`)
      .then((response) => {
        this.contacts = response.data;
      });
    },
  },
  mounted() {
    this.getInvoice();
  },
};
</script>